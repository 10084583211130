<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-data-table
                :headers="felter"
                :items="kommentarer"
                item-key="kommentarId"
                :expanded.sync="ekspandert"
                sort-by="opprettet"
                :custom-sort="customSort"
                :sort-desc="false"
                no-data-text="Ingen kommentarer."
                :items-per-page="-1"
                :single-expand="false"
                must-sort
                hide-default-footer
            >
                <template v-slot:header.rediger>
                    <v-btn v-on:click="add()" fab small color="primary">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>

                <template v-slot:item.kommentar="{ item }">
                    <div
                        v-if="item.kommentarId && !item.rediger"
                        v-html="nl2br(item.kommentar)"
                        v-bind:class="{ 'py-2': !item.clamp, 'v-data-col--clamp': item.clamp }"
                        v-on:click="item.clamp = !item.clamp"
                    ></div>
                </template>
                <template v-slot:item.vedlegg="{ item }">
                    <td class="py-4" style="vertical-align: top; text-align: center" v-on:click="downloadAttachment(item)">
                        <template v-if="!item.rediger && item.vedlegg && item.vedlegg.length > 0">
                            <v-icon v-if="item.vedlegg[0].type.match(/docx/)" style="cursor: pointer">mdi-file-word-box</v-icon>
                            <v-icon v-if="item.vedlegg[0].type.match(/pdf/)" style="cursor: pointer">mdi-file-pdf-box</v-icon>
                            <v-icon v-else style="cursor: pointer">mdi-file</v-icon>
                        </template>
                    </td>
                </template>
                <template v-slot:item.opprettet="{ item }">
                    <td v-if="item.kommentarId && !item.rediger" class="py-4" style="vertical-align: top">
                        {{ item.opprettet | format('DD.MM.YYYY') }}
                    </td>
                </template>
                <template v-slot:item.rediger="{ item }">
                    <td v-if="item.kommentarId && !item.rediger" class="py-4" style="vertical-align: top; text-align: center">
                        <v-icon small v-on:click="edit(item)">mdi-pencil</v-icon>
                    </td>
                </template>

                <template v-slot:expanded-item="{ item }">
                    <td :colspan="felter.length" class="py-6" style="height: auto; background-color: #eee">
                        <v-form ref="form" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea ref="nyKommentar" v-model="item.nyKommentar" rows="2" auto-grow label="Kommentar"> </v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-chip
                                        v-if="item.vedlegg && item.vedlegg.length > 0"
                                        close
                                        @click:close="removeAttachment(item)"
                                        v-on:click="downloadAttachment(item)"
                                        >{{ item.vedlegg[0].filnavn }}</v-chip
                                    >
                                    <v-file-input
                                        ref="nyttVedlegg"
                                        v-model="item.nyttVedlegg"
                                        truncate-length="22"
                                        :label="item.vedlegg && item.vedlegg.length ? 'Erstatt vedlegg' : 'Vedlegg'"
                                    >
                                        ></v-file-input
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" class="mr-4" v-on:click="save(item)"> Lagre </v-btn>
                                    <v-btn v-if="item.kommentarId" text v-on:click="remove(item)"> Slett</v-btn>
                                    <v-btn text v-on:click="cancel(item)"> Lukk</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
    name: '',
    props: {
        value: {
            type: Object,
        },
        type: {
            type: String,
            required : true
        },
    },
    data() {
        return {
            felter: [
                { text: 'Kommentar', value: 'kommentar', sortable: false },
                { text: 'Vedlegg', value: 'vedlegg', sortable: false, width: '8px' },
                { text: 'Opprettet', value: 'opprettet', width: '140px' },
                { text: '', value: 'rediger', sortable: false, width: '40px' },
                { text: '', value: 'data-table-expand' },
            ],

            kommentarer: [],
            ekspandert: [],

            parent: {},
        };
    },
    computed: {
        changed: function () {
            return false;
        },
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    watch: {
        value: async function (value) {
            this.parent = this.value = value;
            await this.update();
        },
    },

    /**
     * created
     */
    created: async function () {
        if (this.value) {
            this.parent = this.value;
            await this.update();
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson', 'formatGriefGroup']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * update
         */
        update: async function (parent) {
            if (parent) {

                if(this.type == 'person') {
                    this.parent = await this.formatPerson(parent);
                } else if(this.type == 'sorggruppe') {
                    this.parent = await this.formatGriefGroup(parent);
                }
                this.$emit('updated', this.parent);
            }

            this.ekspandert = [];
            this.kommentarer = [];

            let index = 1;
            if(Array.isArray(this.parent.kommentarer)) {
                for (let kommentar of this.parent.kommentarer) {
                    kommentar = Object.assign({}, kommentar);
                    kommentar.rediger = false;
                    kommentar.clamp = true;
                    kommentar.index = index++;

                    this.kommentarer.push(kommentar);
                }
            }
        },

        /**
         * add
         */
        add: function () {
            const added = this.kommentarer.filter((e) => {
                return !e.kommentarId;
            });

            if (!added.length) {
                const item = { kommentar: '', opprettet: moment(), rediger: true };
                this.kommentarer.unshift(item);
                this.ekspandert.push(item);

                window.setTimeout(() => {
                    this.$refs.nyKommentar.focus();
                }, 200);
            }
        },

        /**
         * edit
         */
        edit: async function (item) {
            item.rediger = true;
            item.nyKommentar = item.kommentar;
            this.ekspandert.push(item);
        },

        /**
         * cancel
         */
        cancel: function (item) {
            item.rediger = false;
            this.ekspandert = this.ekspandert.filter((e) => {
                return e !== item;
            });

            if (!item.kommentarId) {
                this.kommentarer = this.kommentarer.filter((e) => {
                    return e !== item;
                });
            }
        },

        /**
         * save
         */
        save: async function (item) {
            let data = new FormData();
            data.append('kommentar', item.nyKommentar);
            if (!item.kommentarId && item.nyttVedlegg) {
                data.append('vedlegg', item.nyttVedlegg);
            }

            let response = await this.request({
                method: item.kommentarId ? 'put' : 'post',
                url: '/' + this.type + '/' + this.parent.id + '/kommentar' + (item.kommentarId ? '/' + item.kommentarId : ''),
                data,
            });

            if (response && typeof response.id != 'undefined') {
                if (item.kommentarId && item.nyttVedlegg) {
                    if (item.vedlegg && item.vedlegg.length > 0) {
                        response = await this.request({
                            method: 'delete',
                            url: '/' + this.type + '/' + this.parent.id + '/kommentar/' + item.kommentarId + '/vedlegg/' + item.vedlegg[0].filnavn,
                        });
                    }

                    data = new FormData();
                    data.append('vedlegg', item.nyttVedlegg);

                    response = await this.request({
                        method: 'post',
                        url: '/' + this.type + '/' + this.parent.id + '/kommentar/' + item.kommentarId + '/vedlegg',
                        data: data,
                    });

                    if (!response || typeof response.id == 'undefined') {
                        this.snackbar('Det oppstod en feil');
                        return;
                    }

                    //console.log(response);
                }

                item.rediger = false;
                await this.update(response);
                this.snackbar(item.kommentarId ? 'Lagret' : 'Opprettet');
            }
        },

        /**
         * remove
         */
        remove: async function (item) {
            if (await this.confirm({ title: 'Slette?', message: 'Sikker på at du vil slette komentaren?' })) {
                if (item.kommentarId) {
                    const response = await this.request({
                        method: 'delete',
                        url: '/' + this.type + '/' + this.parent.id + '/kommentar/' + item.kommentarId,
                    });

                    if (response && typeof response.id != 'undefined') {
                        await this.update(response);
                        this.snackbar('Slettet');
                    }
                }
            }
        },

        /**
         * removeAttachment
         */
        removeAttachment: async function (item) {
            if (item.vedlegg && item.vedlegg.length > 0) {
                if (await this.confirm({ title: 'Fjerne?', message: 'Sikker på at du vil fjerne vedlegget?' })) {
                    const response = await this.request({
                        method: 'delete',
                        url: '/' + this.type + '/' + this.parent.id + '/kommentar/' + item.kommentarId + '/vedlegg/' + item.vedlegg[0].filnavn,
                    });
                    if (response && typeof response.id != 'undefined') {
                        for (let kommentar of this.kommentarer) {
                            if (kommentar.kommentarId == item.kommentarId) {
                                kommentar.vedlegg = [];
                                console.log('2');
                                this.snackbar('Fjernet');
                                break;
                            }
                        }
                    }
                }
            }
        },

        /**
         * downloadAttachment
         */
        downloadAttachment: async function (item) {
            if (item.vedlegg && item.vedlegg.length > 0) {
                const response = await this.request({
                    method: 'get',
                    responseType: 'blob',
                    url: '/' + this.type + '/' + this.parent.id + '/kommentar/' + item.kommentarId + '/vedlegg/' + item.vedlegg[0].filnavn,
                });

                if (typeof response == 'object' && response.constructor.name == 'Blob') {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', item.vedlegg[0].filnavn);
                    document.body.appendChild(link);
                    link.click();
                }
            }
        },

        /**
         * nl2br
         */
        nl2br: function (str) {
            return str ? str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>') : '';
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index == 'opprettet') {
                items.sort((a, b) => {
                    if (isDesc[0]) {
                        return a['index'] - b['index'];
                    } else {
                        return b['index'] - a['index'];
                    }
                });
            }
            return items;
        },
    },
};
</script>
