<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col v-if="grupper && grupper.length" cols="12" sm="12">
                        <label>Sorggruppe(r)</label><br />
                        <div>
                            <v-chip class="ma-2" v-for="(gruppe, index) of grupper" :to="'/sorg/gruppe/' + gruppe.id" v-bind:key="index">
                                {{ gruppe.tittel }}</v-chip
                            >
                        </div>
                        <br />
                        <v-divider></v-divider>
                    </v-col>

                    <v-divider></v-divider>
                    <v-col cols="12" sm="12">
                        <v-select
                            v-model="sorginformasjon.sorgTypeId"
                            :items="typer"
                            item-value="id"
                            item-text="navn"
                            :rules="rules.sorgTypeId"
                            label="Ønsket sorgruppe"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-text-field v-model="sorginformasjon.tapsbeskrivelse" label="Tap"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="sorginformasjon.tapsdato" label="Tapdato"></date-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="sorginformasjon.dodsaarsak" label="Dødsårsak" rows="2"></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="sorginformasjon.kommentar" label="Kommentar"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-text-field v-model="sorginformasjon.forstegangssamtalePersonId" label="Førstegangssamtale"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="sorginformasjon.forstegangssamtaleDato" label="Samtaledato"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="sorginformasjon.sendtSMSDato" label="Sendt SMS"></date-field>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-select v-model="sorginformasjon.tilordnetSorgGruppeId" :items="kanTilordnesGruppper" item-value="id" item-text="tittel" label="Skal inn i gruppe">

                        </v-select>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="changed" color="primary" v-on:click="save">Lagre</v-btn>
            <v-btn text v-if="changed" color="primary" v-on:click="cancel">Angre endringer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: '',
    components: {
        DateField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        const sorginformasjon = {
            sorgTypeId: null,
            tapsbeskrivelse: '',
            tapsdato: null,
            dodsaarsak: '',
            kommentar: '',
            forstegangssamtalePersonId: null,
            forstegangssamtaleDato: null,
            sendtSMSDato : null,
            tilordnetSorgGruppeId:null,
        };

        return {
            changed: false,

            person: {},

            sorginformasjon: sorginformasjon,
            ingenSorgInformasjon: Object.assign({}, sorginformasjon),

            typer: [],
            grupper: [],
            alleGrupper: [],

            rules: {
                sorgTypeId: [(v) => !!v || 'Må oppgis'],
            },
        };
    },
    computed: {
        ...mapGetters('api', ['hasUserRole']),
        kanTilordnesGruppper: function() {
            const grupper = [];
            for (const gruppe of this.alleGrupper) {
                if(!this.sorginformasjon.sorgTypeId || gruppe.type.id == this.sorginformasjon.sorgTypeId) {
                    grupper.push(gruppe);
                } else if (this.sorginformasjon.tilordnetSorgGruppeId == gruppe.id) {
                    grupper.push(gruppe);
                }
            }

            grupper.sort((a, b) => a.tittel.localeCompare(b.tittel, 'no', { numeric: true, sensitivity: 'base' }) );
            grupper.unshift({ id: null, tittel: '' });
            return grupper;
        }
    },
    watch: {
        value: async function (value) {
            this.person = this.value = value;
            this.load();
        },
        sorginformasjon: {
            handler: function () {
                this.changed = true;
            },
            deep: true,
        },
    },

    /**
     * created
     */
    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/sorggruppe/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }

        response = await this.request({
            method: 'get',
            url: '/sorggruppe',
            params: {
                bareAktive: true,
            },
        });
        if (response && Array.isArray(response)) {
            this.alleGrupper = response;
        }

        if (this.value) {
            this.person = this.value;
        }
        await this.load();
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * load
         */
        load: async function () {
            if (this.person) {
                const response = await this.request({
                    method: 'get',
                    url: '/sorggruppe',
                });

                this.grupper = [];

                if (response && Array.isArray(response)) {
                    for (const gruppe of response) {
                        if (gruppe.deltakere) {
                            for (const deltaker of gruppe.deltakere) {
                                if (deltaker.person && !deltaker.fjernet && deltaker.person.id == this.person.id) {
                                    this.grupper.push(gruppe);
                                    break;
                                }
                            }
                        }
                    }
                }
                this.update();
            }
        },

        /**
         * update
         */
        update: function () {
            if (this.person && this.person.sorginformasjon) {
                for (const field in this.sorginformasjon) {
                    this.sorginformasjon[field] = this.person.sorginformasjon[field];
                }
                if (this.person.sorginformasjon.sorgType) {
                    this.sorginformasjon.sorgTypeId = this.person.sorginformasjon.sorgType.id;
                }

                if (!this.sorginformasjon.kommentar && this.sorginformasjon.henvistAv) {
                    this.sorginformasjon.kommentar = this.sorginformasjon.henvistAv;
                    this.sorginformasjon.henvistAv = null;
                }
            } else {
                for (const field in this.sorginformasjon) {
                    this.sorginformasjon[field] = this.ingenSorgInformasjon[field];
                }
            }

            this.$refs.form.resetValidation();

            window.setTimeout(() => {
                this.changed = false;
            }, 100);
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                try {
                    let person = null;

                    if (this.changed) {
                        const data = Object.assign({}, this.sorginformasjon);
                        for (const field of ['tapsdato', 'forstegangssamtaleDato', 'sendtSMSDato']) {
                            if (data[field]) {
                                data[field] = data[field].format('DD.MM.YYYY');
                            }
                        }

                        const response = await this.request({
                            method: 'post',
                            url: '/person/' + this.person.id + '/sorginformasjon',
                            data: data,
                        });

                        if (response && typeof response.id != 'undefined') {
                            person = response;
                        }
                    }

                    // success
                    if (person) {
                        this.person = await this.formatPerson(person);
                        this.update();

                        this.$emit('input', this.person);

                        this.snackbar('Lagret');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },

        /**
         * cancel
         */
        cancel: function () {
            this.changed = false;
        },
    },
};
</script>
<style lang="scss"></style>
