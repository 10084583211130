var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"max-width":"800px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.felter,"items":_vm.kommentarer,"item-key":"kommentarId","expanded":_vm.ekspandert,"sort-by":"opprettet","custom-sort":_vm.customSort,"sort-desc":false,"no-data-text":"Ingen kommentarer.","items-per-page":-1,"single-expand":false,"must-sort":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.ekspandert=$event}},scopedSlots:_vm._u([{key:"header.rediger",fn:function(){return [_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true},{key:"item.kommentar",fn:function(ref){
var item = ref.item;
return [(item.kommentarId && !item.rediger)?_c('div',{class:{ 'py-2': !item.clamp, 'v-data-col--clamp': item.clamp },domProps:{"innerHTML":_vm._s(_vm.nl2br(item.kommentar))},on:{"click":function($event){item.clamp = !item.clamp}}}):_vm._e()]}},{key:"item.vedlegg",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-4",staticStyle:{"vertical-align":"top","text-align":"center"},on:{"click":function($event){return _vm.downloadAttachment(item)}}},[(!item.rediger && item.vedlegg && item.vedlegg.length > 0)?[(item.vedlegg[0].type.match(/docx/))?_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-file-word-box")]):_vm._e(),(item.vedlegg[0].type.match(/pdf/))?_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-file-pdf-box")]):_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-file")])]:_vm._e()],2)]}},{key:"item.opprettet",fn:function(ref){
var item = ref.item;
return [(item.kommentarId && !item.rediger)?_c('td',{staticClass:"py-4",staticStyle:{"vertical-align":"top"}},[_vm._v(" "+_vm._s(_vm._f("format")(item.opprettet,'DD.MM.YYYY'))+" ")]):_vm._e()]}},{key:"item.rediger",fn:function(ref){
var item = ref.item;
return [(item.kommentarId && !item.rediger)?_c('td',{staticClass:"py-4",staticStyle:{"vertical-align":"top","text-align":"center"}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-6",staticStyle:{"height":"auto","background-color":"#eee"},attrs:{"colspan":_vm.felter.length}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"nyKommentar",attrs:{"rows":"2","auto-grow":"","label":"Kommentar"},model:{value:(item.nyKommentar),callback:function ($$v) {_vm.$set(item, "nyKommentar", $$v)},expression:"item.nyKommentar"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(item.vedlegg && item.vedlegg.length > 0)?_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.removeAttachment(item)},"click":function($event){return _vm.downloadAttachment(item)}}},[_vm._v(_vm._s(item.vedlegg[0].filnavn))]):_vm._e(),_c('v-file-input',{ref:"nyttVedlegg",attrs:{"truncate-length":"22","label":item.vedlegg && item.vedlegg.length ? 'Erstatt vedlegg' : 'Vedlegg'},model:{value:(item.nyttVedlegg),callback:function ($$v) {_vm.$set(item, "nyttVedlegg", $$v)},expression:"item.nyttVedlegg"}},[_vm._v(" >")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.save(item)}}},[_vm._v(" Lagre ")]),(item.kommentarId)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" Slett")]):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel(item)}}},[_vm._v(" Lukk")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }